.twa {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 .05em 0 .1em;
  vertical-align: -0.1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1em 1em;
}

$size-map: (
  "lg": 1.33,
  "2x": 2,
  "3x": 3,
  "4x": 4,
  "5x": 5
);

@each $name, $size in $size-map {
  .twa-#{$name} {
    height: 1em * $size;
    width: 1em * $size;
    margin: 0 .05em * $size 0 .1em * $size;
    vertical-align: -0.1em * $size;
    background-size: 1em * $size 1em * $size;
  }
}

$emoji-map: (
  "1f604": "smile",
  "1f606": "laughing",
  "1f60a": "blush",
  "1f603": "smiley",
  "263a": "relaxed",
  "1f60f": "smirk",
  "1f60d": "heart-eyes",
  "1f618": "kissing-heart",
  "1f61a": "kissing-closed-eyes",
  "1f633": "flushed",
  "1f625": "relieved",
  "1f60c": "satisfied",
  "1f601": "grin",
  "1f609": "wink",
  "1f61c": "stuck-out-tongue-winking-eye",
  "1f61d": "stuck-out-tongue-closed-eyes",
  "1f600": "grinning",
  "1f617": "kissing",
  "1f619": "kissing-smiling-eyes",
  "1f61b": "stuck-out-tongue",
  "1f634": "sleeping",
  "1f61f": "worried",
  "1f626": "frowning",
  "1f627": "anguished",
  "1f62e": "open-mouth",
  "1f62c": "grimacing",
  "1f615": "confused",
  "1f62f": "hushed",
  "1f611": "expressionless",
  "1f612": "unamused",
  "1f605": "sweat-smile",
  "1f613": "sweat",
  "1f629": "weary",
  "1f614": "pensive",
  "1f61e": "disappointed",
  "1f616": "confounded",
  "1f628": "fearful",
  "1f630": "cold-sweat",
  "1f623": "persevere",
  "1f622": "cry",
  "1f62d": "sob",
  "1f602": "joy",
  "1f632": "astonished",
  "1f631": "scream",
  "1f62b": "tired-face",
  "1f620": "angry",
  "1f621": "rage",
  "1f624": "triumph",
  "1f62a": "sleepy",
  "1f60b": "yum",
  "1f637": "mask",
  "1f60e": "sunglasses",
  "1f635": "dizzy-face",
  "1f47f": "imp",
  "1f608": "smiling-imp",
  "1f610": "neutral-face",
  "1f636": "no-mouth",
  "1f607": "innocent",
  "1f47d": "alien",
  "1f49b": "yellow-heart",
  "1f499": "blue-heart",
  "1f49c": "purple-heart",
  "2764": "heart",
  "1f49a": "green-heart",
  "1f494": "broken-heart",
  "1f493": "heartbeat",
  "1f497": "heartpulse",
  "1f495": "two-hearts",
  "1f49e": "revolving-hearts",
  "1f498": "cupid",
  "1f496": "sparkling-heart",
  "2728": "sparkles",
  "2b50": "star",
  "1f31f": "star2",
  "1f4ab": "dizzy",
  "1f4a5": "boom",
  "1f4a2": "anger",
  "2757": "exclamation",
  "2753": "question",
  "2755": "grey-exclamation",
  "2754": "grey-question",
  "1f4a4": "zzz",
  "1f4a8": "dash",
  "1f4a6": "sweat-drops",
  "1f3b6": "notes",
  "1f3b5": "musical-note",
  "1f525": "fire",
  "1f4a9": "poop",
  "1f44d": "thumbsup",
  "1f44e": "thumbsdown",
  "1f44c": "ok-hand",
  "1f44a": "punch",
  "270a": "fist",
  "270c": "v",
  "1f44b": "wave",
  "270b": "hand",
  "1f450": "open-hands",
  "261d": "point-up",
  "1f447": "point-down",
  "1f448": "point-left",
  "1f449": "point-right",
  "1f64c": "raised-hands",
  "1f64f": "pray",
  "1f446": "point-up-2",
  "1f44f": "clap",
  "1f4aa": "muscle",
  "1f6b6": "walking",
  "1f3c3": "runner",
  "1f46b": "couple",
  "1f46a": "family",
  "1f46c": "two-men-holding-hands",
  "1f46d": "two-women-holding-hands",
  "1f483": "dancer",
  "1f46f": "dancers",
  "1f646": "ok-woman",
  "1f645": "no-good",
  "1f481": "information-desk-person",
  "1f64b": "raised-hand",
  "1f470": "bride-with-veil",
  "1f64e": "person-with-pouting-face",
  "1f64d": "person-frowning",
  "1f647": "bow",
  "1f48f": "couplekiss",
  "1f491": "couple-with-heart",
  "1f486": "massage",
  "1f487": "haircut",
  "1f485": "nail-care",
  "1f466": "boy",
  "1f467": "girl",
  "1f469": "woman",
  "1f468": "man",
  "1f476": "baby",
  "1f475": "older-woman",
  "1f474": "older-man",
  "1f471": "person-with-blond-hair",
  "1f472": "man-with-gua-pi-mao",
  "1f473": "man-with-turban",
  "1f477": "construction-worker",
  "1f46e": "cop",
  "1f47c": "angel",
  "1f478": "princess",
  "1f63a": "smiley-cat",
  "1f638": "smile-cat",
  "1f63b": "heart-eyes-cat",
  "1f63d": "kissing-cat",
  "1f63c": "smirk-cat",
  "1f640": "scream-cat",
  "1f63f": "crying-cat-face",
  "1f639": "joy-cat",
  "1f63e": "pouting-cat",
  "1f479": "japanese-ogre",
  "1f47a": "japanese-goblin",
  "1f648": "see-no-evil",
  "1f649": "hear-no-evil",
  "1f64a": "speak-no-evil",
  "1f482": "guardsman",
  "1f480": "skull",
  "1f463": "feet",
  "1f444": "lips",
  "1f48b": "kiss",
  "1f4a7": "droplet",
  "1f442": "ear",
  "1f440": "eyes",
  "1f443": "nose",
  "1f445": "tongue",
  "1f48c": "love-letter",
  "1f464": "bust-in-silhouette",
  "1f465": "busts-in-silhouette",
  "1f4ac": "speech-balloon",
  "1f4ad": "thought-balloon",
  "2600": "sunny",
  "2614": "umbrella",
  "2601": "butt",
  "2744": "snowflake",
  "26c4": "snowman",
  "26a1": "zap",
  "1f300": "cyclone",
  "1f301": "foggy",
  "1f30a": "ocean",
  "1f431": "cat",
  "1f436": "dog",
  "1f42d": "mouse",
  "1f439": "hamster",
  "1f430": "rabbit",
  "1f43a": "wolf",
  "1f438": "frog",
  "1f42f": "tiger",
  "1f428": "koala",
  "1f43b": "bear",
  "1f437": "pig",
  "1f43d": "pig-nose",
  "1f42e": "cow",
  "1f417": "boar",
  "1f435": "monkey-face",
  "1f412": "monkey",
  "1f434": "horse",
  "1f40e": "racehorse",
  "1f42b": "camel",
  "1f411": "sheep",
  "1f418": "elephant",
  "1f43c": "panda-face",
  "1f40d": "snake",
  "1f426": "bird",
  "1f424": "baby-chick",
  "1f425": "hatched-chick",
  "1f423": "hatching-chick",
  "1f414": "chicken",
  "1f427": "penguin",
  "1f422": "turtle",
  "1f41b": "bug",
  "1f41d": "honeybee",
  "1f41c": "ant",
  "1f41e": "beetle",
  "1f40c": "snail",
  "1f419": "octopus",
  "1f420": "tropical-fish",
  "1f41f": "fish",
  "1f433": "whale",
  "1f40b": "whale2",
  "1f42c": "dolphin",
  "1f404": "cow2",
  "1f40f": "ram",
  "1f400": "rat",
  "1f403": "water-buffalo",
  "1f405": "tiger2",
  "1f407": "rabbit2",
  "1f409": "dragon",
  "1f410": "goat",
  "1f413": "rooster",
  "1f415": "dog2",
  "1f416": "pig2",
  "1f401": "mouse2",
  "1f402": "ox",
  "1f432": "dragon-face",
  "1f421": "blowfish",
  "1f40a": "crocodile",
  "1f42a": "dromedary-camel",
  "1f406": "leopard",
  "1f408": "cat2",
  "1f429": "poodle",
  "1f43e": "paw-prints",
  "1f490": "bouquet",
  "1f338": "cherry-blossom",
  "1f337": "tulip",
  "1f340": "four-leaf-clover",
  "1f339": "rose",
  "1f33b": "sunflower",
  "1f33a": "hibiscus",
  "1f341": "maple-leaf",
  "1f343": "leaves",
  "1f342": "fallen-leaf",
  "1f33f": "herb",
  "1f344": "mushroom",
  "1f335": "cactus",
  "1f334": "palm-tree",
  "1f332": "evergreen-tree",
  "1f333": "deciduous-tree",
  "1f330": "chestnut",
  "1f331": "seedling",
  "1f33c": "blossom",
  "1f33e": "ear-of-rice",
  "1f41a": "shell",
  "1f310": "globe-with-meridians",
  "1f31e": "sun-with-face",
  "1f31d": "full-moon-with-face",
  "1f31a": "new-moon-with-face",
  "1f311": "new-moon",
  "1f312": "waxing-crescent-moon",
  "1f313": "first-quarter-moon",
  "1f314": "waxing-gibbous-moon",
  "1f315": "full-moon",
  "1f316": "waning-gibbous-moon",
  "1f317": "last-quarter-moon",
  "1f318": "waning-crescent-moon",
  "1f31c": "last-quarter-moon-with-face",
  "1f31b": "first-quarter-moon-with-face",
  "1f319": "moon",
  "1f30d": "earth-africa",
  "1f30e": "earth-americas",
  "1f30f": "earth-asia",
  "1f30b": "volcano",
  "1f30c": "milky-way",
  "26c5": "partly-sunny",
  "1f38d": "bamboo",
  "1f49d": "gift-heart",
  "1f38e": "dolls",
  "1f392": "school-satchel",
  "1f393": "mortar-board",
  "1f38f": "flags",
  "1f386": "fireworks",
  "1f387": "sparkler",
  "1f390": "wind-chime",
  "1f391": "rice-scene",
  "1f383": "jack-o-lantern",
  "1f47b": "ghost",
  "1f385": "santa",
  "1f3b1": "8ball",
  "23f0": "alarm-clock",
  "1f34e": "apple",
  "1f3a8": "art",
  "1f37c": "baby-bottle",
  "1f388": "balloon",
  "1f34c": "banana",
  "1f4ca": "bar-chart",
  "26be": "baseball",
  "1f3c0": "basketball",
  "1f6c0": "bath",
  "1f6c1": "bathtub",
  "1f50b": "battery",
  "1f37a": "beer",
  "1f37b": "beers",
  "1f514": "bell",
  "1f371": "bento",
  "1f6b4": "bicyclist",
  "1f459": "bikini",
  "1f382": "birthday",
  "1f0cf": "black-joker",
  "2712": "black-nib",
  "1f4d8": "blue-book",
  "1f4a3": "bomb",
  "1f516": "bookmark",
  "1f4d1": "bookmark-tabs",
  "1f4da": "books",
  "1f462": "boot",
  "1f3b3": "bowling",
  "1f35e": "bread",
  "1f4bc": "briefcase",
  "1f4a1": "bulb",
  "1f370": "cake",
  "1f4c6": "calendar",
  "1f4f2": "calling",
  "1f4f7": "camera",
  "1f36c": "candy",
  "1f4c7": "card-index",
  "1f4bf": "cd",
  "1f4c9": "chart-with-downwards-trend",
  "1f4c8": "chart-with-upwards-trend",
  "1f352": "cherries",
  "1f36b": "chocolate-bar",
  "1f384": "christmas-tree",
  "1f3ac": "clapper",
  "1f4cb": "clipboard",
  "1f4d5": "closed-book",
  "1f510": "closed-lock-with-key",
  "1f302": "closed-umbrella",
  "2663": "clubs",
  "1f378": "cocktail",
  "2615": "coffee",
  "1f4bb": "computer",
  "1f38a": "confetti-ball",
  "1f36a": "cookie",
  "1f33d": "corn",
  "1f4b3": "credit-card",
  "1f451": "crown",
  "1f52e": "crystal-ball",
  "1f35b": "curry",
  "1f36e": "custard",
  "1f361": "dango",
  "1f3af": "dart",
  "1f4c5": "date",
  "2666": "diamonds",
  "1f4b5": "dollar",
  "1f6aa": "door",
  "1f369": "doughnut",
  "1f457": "dress",
  "1f4c0": "dvd",
  "1f4e7": "e-mail",
  "1f373": "egg",
  "1f346": "eggplant",
  "1f50c": "electric-plug",
  "2709": "email",
  "1f4b6": "euro",
  "1f453": "eyeglasses",
  "1f4e0": "fax",
  "1f4c1": "file-folder",
  "1f365": "fish-cake",
  "1f3a3": "fishing-pole-and-fish",
  "1f526": "flashlight",
  "1f4be": "floppy-disk",
  "1f3b4": "flower-playing-cards",
  "1f3c8": "football",
  "1f374": "fork-and-knife",
  "1f364": "fried-shrimp",
  "1f35f": "fries",
  "1f3b2": "game-die",
  "1f48e": "gem",
  "1f381": "gift",
  "26f3": "golf",
  "1f347": "grapes",
  "1f34f": "green-apple",
  "1f4d7": "green-book",
  "1f3b8": "guitar",
  "1f52b": "gun",
  "1f354": "hamburger",
  "1f528": "hammer",
  "1f45c": "handbag",
  "1f3a7": "headphones",
  "2665": "hearts",
  "1f506": "high-brightness",
  "1f460": "high-heel",
  "1f52a": "hocho",
  "1f36f": "honey-pot",
  "1f3c7": "horse-racing",
  "231b": "hourglass",
  "23f3": "hourglass-flowing-sand",
  "1f368": "ice-cream",
  "1f366": "icecream",
  "1f4e5": "inbox-tray",
  "1f4e8": "incoming-envelope",
  "1f4f1": "iphone",
  "1f456": "jeans",
  "1f511": "key",
  "1f458": "kimono",
  "1f4d2": "ledger",
  "1f34b": "lemon",
  "1f484": "lipstick",
  "1f512": "lock",
  "1f50f": "lock-with-ink-pen",
  "1f36d": "lollipop",
  "27bf": "loop",
  "1f4e2": "loudspeaker",
  "1f505": "low-brightness",
  "1f50d": "mag",
  "1f50e": "mag-right",
  "1f004": "mahjong",
  "1f4eb": "mailbox",
  "1f4ea": "mailbox-closed",
  "1f4ec": "mailbox-with-mail",
  "1f4ed": "mailbox-with-no-mail",
  "1f45e": "mans-shoe",
  "1f356": "meat-on-bone",
  "1f4e3": "mega",
  "1f348": "melon",
  "1f4dd": "memo",
  "1f3a4": "microphone",
  "1f52c": "microscope",
  "1f4bd": "minidisc",
  "1f4b8": "money-with-wings",
  "1f4b0": "moneybag",
  "1f6b5": "mountain-bicyclist",
  "1f3a5": "movie-camera",
  "1f3b9": "musical-keyboard",
  "1f3bc": "musical-score",
  "1f507": "mute",
  "1f4db": "name-badge",
  "1f454": "necktie",
  "1f4f0": "newspaper",
  "1f515": "no-bell",
  "1f4d3": "notebook",
  "1f4d4": "notebook-with-decorative-cover",
  "1f529": "nut-and-bolt",
  "1f362": "oden",
  "1f4c2": "open-file-folder",
  "1f4d9": "orange-book",
  "1f4e4": "outbox-tray",
  "1f4c4": "page-facing-up",
  "1f4c3": "page-with-curl",
  "1f4df": "pager",
  "1f4ce": "paperclip",
  "1f351": "peach",
  "1f350": "pear",
  "270f": "pencil2",
  "260e": "phone",
  "1f48a": "pill",
  "1f34d": "pineapple",
  "1f355": "pizza",
  "1f4ef": "postal-horn",
  "1f4ee": "postbox",
  "1f45d": "pouch",
  "1f357": "poultry-leg",
  "1f4b7": "pound",
  "1f45b": "purse",
  "1f4cc": "pushpin",
  "1f4fb": "radio",
  "1f35c": "ramen",
  "1f380": "ribbon",
  "1f35a": "rice",
  "1f359": "rice-ball",
  "1f358": "rice-cracker",
  "1f48d": "ring",
  "1f3c9": "rugby-football",
  "1f3bd": "running-shirt-with-sash",
  "1f376": "sake",
  "1f461": "sandal",
  "1f4e1": "satellite",
  "1f3b7": "saxophone",
  "2702": "scissors",
  "1f4dc": "scroll",
  "1f4ba": "seat",
  "1f367": "shaved-ice",
  "1f455": "shirt",
  "1f6bf": "shower",
  "1f3bf": "ski",
  "1f6ac": "smoking",
  "1f3c2": "snowboarder",
  "26bd": "soccer",
  "1f509": "sound",
  "1f47e": "space-invader",
  "2660": "spades",
  "1f35d": "spaghetti",
  "1f50a": "speaker",
  "1f372": "stew",
  "1f4cf": "straight-ruler",
  "1f353": "strawberry",
  "1f3c4": "surfer",
  "1f363": "sushi",
  "1f360": "sweet-potato",
  "1f3ca": "swimmer",
  "1f489": "syringe",
  "1f389": "tada",
  "1f38b": "tanabata-tree",
  "1f34a": "tangerine",
  "1f375": "tea",
  "1f4de": "telephone-receiver",
  "1f52d": "telescope",
  "1f3be": "tennis",
  "1f6bd": "toilet",
  "1f345": "tomato",
  "1f3a9": "tophat",
  "1f4d0": "triangular-ruler",
  "1f3c6": "trophy",
  "1f379": "tropical-drink",
  "1f3ba": "trumpet",
  "1f4fa": "tv",
  "1f513": "unlock",
  "1f4fc": "vhs",
  "1f4f9": "video-camera",
  "1f3ae": "video-game",
  "1f3bb": "violin",
  "231a": "watch",
  "1f349": "watermelon",
  "1f377": "wine-glass",
  "1f45a": "womans-clothes",
  "1f452": "womans-hat",
  "1f527": "wrench",
  "1f4b4": "yen",
  "1f6a1": "aerial-tramway",
  "2708": "airplane",
  "1f691": "ambulance",
  "2693": "anchor",
  "1f69b": "articulated-lorry",
  "1f3e7": "atm",
  "1f3e6": "bank",
  "1f488": "barber",
  "1f530": "beginner",
  "1f6b2": "bike",
  "1f699": "blue-car",
  "26f5": "boat",
  "1f309": "bridge-at-night",
  "1f685": "bullettrain-front",
  "1f684": "bullettrain-side",
  "1f68c": "bus",
  "1f68f": "busstop",
  "1f697": "car",
  "1f3a0": "carousel-horse",
  "1f3c1": "checkered-flag",
  "26ea": "church",
  "1f3aa": "circus-tent",
  "1f307": "city-sunrise",
  "1f306": "city-sunset",
  "1f6a7": "construction",
  "1f3ea": "convenience-store",
  "1f38c": "crossed-flags",
  "1f3ec": "department-store",
  "1f3f0": "european-castle",
  "1f3e4": "european-post-office",
  "1f3ed": "factory",
  "1f3a1": "ferris-wheel",
  "1f692": "fire-engine",
  "26f2": "fountain",
  "26fd": "fuelpump",
  "1f681": "helicopter",
  "1f3e5": "hospital",
  "1f3e8": "hotel",
  "2668": "hotsprings",
  "1f3e0": "house",
  "1f3e1": "house-with-garden",
  "1f5fe": "japan",
  "1f3ef": "japanese-castle",
  "1f688": "light-rail",
  "1f3e9": "love-hotel",
  "1f690": "minibus",
  "1f69d": "monorail",
  "1f5fb": "mount-fuji",
  "1f6a0": "mountain-cableway",
  "1f69e": "mountain-railway",
  "1f5ff": "moyai",
  "1f3e2": "office",
  "1f698": "oncoming-automobile",
  "1f68d": "oncoming-bus",
  "1f694": "oncoming-police-car",
  "1f696": "oncoming-taxi",
  "1f3ad": "performing-arts",
  "1f693": "police-car",
  "1f3e3": "post-office",
  "1f683": "railway-car",
  "1f308": "rainbow",
  "1f680": "rocket",
  "1f3a2": "roller-coaster",
  "1f6a8": "rotating-light",
  "1f4cd": "round-pushpin",
  "1f6a3": "rowboat",
  "1f3eb": "school",
  "1f6a2": "ship",
  "1f3b0": "slot-machine",
  "1f6a4": "speedboat",
  "1f303": "stars",
  "1f689": "station",
  "1f5fd": "statue-of-liberty",
  "1f682": "steam-locomotive",
  "1f305": "sunrise",
  "1f304": "sunrise-over-mountains",
  "1f69f": "suspension-railway",
  "1f695": "taxi",
  "26fa": "tent",
  "1f3ab": "ticket",
  "1f5fc": "tokyo-tower",
  "1f69c": "tractor",
  "1f6a5": "traffic-light",
  "1f686": "train2",
  "1f68a": "tram",
  "1f6a9": "triangular-flag-on-post",
  "1f68e": "trolleybus",
  "1f69a": "truck",
  "1f6a6": "vertical-traffic-light",
  "26a0": "warning",
  "1f492": "wedding",
  "1f1ef-1f1f5": "jp",
  "1f1f0-1f1f7": "kr",
  "1f1e8-1f1f3": "cn",
  "1f1fa-1f1f8": "us",
  "1f1eb-1f1f7": "fr",
  "1f1ea-1f1f8": "es",
  "1f1ee-1f1f9": "it",
  "1f1f7-1f1fa": "ru",
  "1f1ec-1f1e7": "gb",
  "1f1e9-1f1ea": "de",
  "1f4af": "100",
  "1f522": "1234",
  "1f170": "a",
  "1f18e": "ab",
  "1f524": "abc",
  "1f521": "abcd",
  "1f251": "accept",
  "2652": "aquarius",
  "2648": "aries",
  "25c0": "arrow-backward",
  "23ec": "arrow-double-down",
  "23eb": "arrow-double-up",
  "2b07": "arrow-down",
  "1f53d": "arrow-down-small",
  "25b6": "arrow-forward",
  "2935": "arrow-heading-down",
  "2934": "arrow-heading-up",
  "2b05": "arrow-left",
  "2199": "arrow-lower-left",
  "2198": "arrow-lower-right",
  "27a1": "arrow-right",
  "21aa": "arrow-right-hook",
  "2b06": "arrow-up",
  "2195": "arrow-up-down",
  "1f53c": "arrow-up-small",
  "2196": "arrow-upper-left",
  "2197": "arrow-upper-right",
  "1f503": "arrows-clockwise",
  "1f504": "arrows-counterclockwise",
  "1f171": "b",
  "1f6bc": "baby-symbol",
  "1f6c4": "baggage-claim",
  "2611": "ballot-box-with-check",
  "203c": "bangbang",
  "26ab": "black-circle",
  "1f532": "black-square-button",
  "264b": "cancer",
  "1f520": "capital-abcd",
  "2651": "capricorn",
  "1f4b9": "chart",
  "1f6b8": "children-crossing",
  "1f3a6": "cinema",
  "1f191": "cl",
  "1f550": "clock1",
  "1f559": "clock10",
  "1f565": "clock1030",
  "1f55a": "clock11",
  "1f566": "clock1130",
  "1f55b": "clock12",
  "1f567": "clock1230",
  "1f55c": "clock130",
  "1f551": "clock2",
  "1f55d": "clock230",
  "1f552": "clock3",
  "1f55e": "clock330",
  "1f553": "clock4",
  "1f55f": "clock430",
  "1f554": "clock5",
  "1f560": "clock530",
  "1f555": "clock6",
  "1f561": "clock630",
  "1f556": "clock7",
  "1f562": "clock730",
  "1f557": "clock8",
  "1f563": "clock830",
  "1f558": "clock9",
  "1f564": "clock930",
  "3297": "congratulations",
  "1f192": "cool",
  "a9": "copyright",
  "27b0": "curly-loop",
  "1f4b1": "currency-exchange",
  "1f6c3": "customs",
  "1f4a0": "diamond-shape-with-a-dot-inside",
  "1f6af": "do-not-litter",
  "38-20e3": "eight",
  "2734": "eight-pointed-black-star",
  "2733": "eight-spoked-asterisk",
  "1f51a": "end",
  "23e9": "fast-forward",
  "35-20e3": "five",
  "34-20e3": "four",
  "1f193": "free",
  "264a": "gemini",
  "23-20e3": "hash",
  "1f49f": "heart-decoration",
  "2714": "heavy-check-mark",
  "2797": "heavy-division-sign",
  "1f4b2": "heavy-dollar-sign",
  "2796": "heavy-minus-sign",
  "2716": "heavy-multiplication-x",
  "2795": "heavy-plus-sign",
  "1f194": "id",
  "1f250": "ideograph-advantage",
  "2139": "information-source",
  "2049": "interrobang",
  "1f51f": "keycap-ten",
  "1f201": "koko",
  "1f535": "large-blue-circle",
  "1f537": "large-blue-diamond",
  "1f536": "large-orange-diamond",
  "1f6c5": "left-luggage",
  "2194": "left-right-arrow",
  "21a9": "leftwards-arrow-with-hook",
  "264c": "leo",
  "264e": "libra",
  "1f517": "link",
  "24c2": "m",
  "1f6b9": "mens",
  "1f687": "metro",
  "1f4f4": "mobile-phone-off",
  "274e": "negative-squared-cross-mark",
  "1f195": "new",
  "1f196": "ng",
  "39-20e3": "nine",
  "1f6b3": "no-bicycles",
  "26d4": "no-entry",
  "1f6ab": "no-entry-sign",
  "1f4f5": "no-mobile-phones",
  "1f6b7": "no-pedestrians",
  "1f6ad": "no-smoking",
  "1f6b1": "non-potable-water",
  "2b55": "o",
  "1f17e": "o2",
  "1f197": "ok",
  "1f51b": "on",
  "31-20e3": "one",
  "26ce": "ophiuchus",
  "1f17f": "parking",
  "303d": "part-alternation-mark",
  "1f6c2": "passport-control",
  "2653": "pisces",
  "1f6b0": "potable-water",
  "1f6ae": "put-litter-in-its-place",
  "1f518": "radio-button",
  "267b": "recycle",
  "1f534": "red-circle",
  "ae": "registered",
  "1f501": "repeat",
  "1f502": "repeat-one",
  "1f6bb": "restroom",
  "23ea": "rewind",
  "1f202": "sa",
  "2650": "sagittarius",
  "264f": "scorpius",
  "3299": "secret",
  "37-20e3": "seven",
  "1f4f6": "signal-strength",
  "36-20e3": "six",
  "1f52f": "six-pointed-star",
  "1f539": "small-blue-diamond",
  "1f538": "small-orange-diamond",
  "1f53a": "small-red-triangle",
  "1f53b": "small-red-triangle-down",
  "1f51c": "soon",
  "1f198": "sos",
  "1f523": "symbols",
  "2649": "taurus",
  "33-20e3": "three",
  "2122": "tm",
  "1f51d": "top",
  "1f531": "trident",
  "1f500": "twisted-rightwards-arrows",
  "32-20e3": "two",
  "1f239": "u5272",
  "1f234": "u5408",
  "1f23a": "u55b6",
  "1f22f": "u6307",
  "1f237": "u6708",
  "1f236": "u6709",
  "1f235": "u6e80",
  "1f21a": "u7121",
  "1f238": "u7533",
  "1f232": "u7981",
  "1f233": "u7a7a",
  "1f51e": "underage",
  "1f199": "up",
  "1f4f3": "vibration-mode",
  "264d": "virgo",
  "1f19a": "vs",
  "3030": "wavy-dash",
  "1f6be": "wc",
  "267f": "wheelchair",
  "2705": "white-check-mark",
  "26aa": "white-circle",
  "1f4ae": "white-flower",
  "1f533": "white-square-button",
  "1f6ba": "womens",
  "274c": "x",
  "30-20e3": "zero"
);

@each $code, $name in $emoji-map {
  .twa-#{$name} {
    background-image: url("https://twemoji.maxcdn.com/svg/#{$code}.svg");
  }
}